"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = draw;

var _paper = require("paper");

var _constants = require("./constants");

var _measurements = require("./measurements");

var REQUIRED_INPUT = ["sprojs", "measurements"];
var REQUIRED_SETTINGS = ["canvasWidth", "canvasHeight"];

function isMissingProperties(object, properties) {
  var missingProperties = properties.filter(function (property) {
    return object[property] == null;
  });
  return missingProperties.length > 0 ? missingProperties : false;
}
/**
 * Draws paper js in context
 * @param {Object} input - user input
 * @param {Object} settings - settings for canvas
 */


function draw() {
  var input = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var missingInputParams = isMissingProperties(input, REQUIRED_INPUT);
  var missingSettingsParams = isMissingProperties(settings, REQUIRED_SETTINGS);

  if (missingInputParams || missingSettingsParams) {
    throw new Error("Missing properties ".concat((missingInputParams || missingSettingsParams).join(", ")));
  }

  var _input$measurements = input.measurements,
      karmV = _input$measurements.v,
      karmH = _input$measurements.h,
      luft = _input$measurements.luft,
      flaggInput = input.flagg,
      _input$sprojs = input.sprojs,
      rombSprojs = _input$sprojs.romb,
      sprojsY = _input$sprojs.y,
      sprojsX = _input$sprojs.x,
      _input$farg = input.farg,
      farg = _input$farg === void 0 ? 1 : _input$farg; // TODO: Refactor reassignment

  var flagg = flaggInput && {
    y: flaggInput.y,
    rombSprojs: flaggInput.sprojs.romb,
    sprojsY: flaggInput.sprojs.y,
    sprojsX: flaggInput.sprojs.x
  };
  console.log(input);
  var canvasWidth = settings.canvasWidth,
      canvasHeight = settings.canvasHeight,
      watermarkUrl = settings.watermarkUrl; //const Karmstart = new Point(0, 0);
  //plugininput

  var Fonsterfarg = new _paper.Color("#ccf8ff");
  var width = canvasWidth * 1 - 60; //canvas width

  var height = canvasHeight * 1 - 60; //canvas height

  var color = new _paper.Color(farg);
  var Linjebredd = 1; //uträkningar för skalning

  var scale = getScale(karmV, karmH, width, height);
  var Karmstart = new _paper.Point((canvasWidth - karmV * scale) / 2, (canvasHeight - karmH * scale) / 2); //variabler för draw

  var Sidokarmlangd = karmH - _constants.Karmoverstycke - _constants.Karmbottenstycke;
  var Karmsidostyckesmyg = _constants.Smyg;

  if (luft > 1) {
    Karmsidostyckesmyg = _constants.Karmsidostycke;
  }

  var Bagbreddglapp = (0, _measurements.getBagbreddglapp)(luft);
  var Bagvidd = (0, _measurements.getBagvidd)(karmV, luft);
  var Baghojd = (0, _measurements.getBaghojd)(karmH, karmV, flagg, luft);
  var Fonstersize = new _paper.Size((0, _measurements.getFonstervidd)(sprojsX, karmV, luft, input.sprojs.size.x), (0, _measurements.getFonsterhojd)(sprojsY, karmH, karmV, flagg, luft, input.sprojs.size.y));
  var rects = [];
  var rots = [];
  var lines = [];
  var colors = [];
  var sprojsromb = []; //KODEN

  Karmar();
  AddFonster();
  DrawWindow();
  DrawText();
  /*
  var group = new Group();
   var mapath = new Path.Rectangle(new Point(200, 300), new Size(100, 100));
  mapath.clipMask = true;
   const raster = new Raster(LOGO_URL);
  raster.position = new Point(200, 300);
  raster.size = new Size(300, 300);
   group.addChild(raster);
  group.addChild(mapath);
   
  mapath.style = {
    fillColor: "black",
    strokeColor: "black",
    strokeWidth: 1,
  };
  */

  function Karmar() {
    rects.push(new _paper.Rectangle(new _paper.Point(0, 0), new _paper.Size(karmV, karmH)));
    colors.push(color);
    rects.push(new _paper.Rectangle(new _paper.Point(0, 0), new _paper.Size(karmV, karmH)));
    colors.push(new _paper.Color(0, 0, 0, 0.4)); //topkarm

    rects.push(new _paper.Rectangle(new _paper.Point(0, 0), new _paper.Size(karmV, _constants.Karmoverstycke)));
    colors.push(color); //vänsterkarm

    rects.push(new _paper.Rectangle(new _paper.Point(0, _constants.Karmoverstycke), new _paper.Size(_constants.Karmsidostycke, Sidokarmlangd)));
    colors.push(color); //högerkarm

    rects.push(new _paper.Rectangle(new _paper.Point(karmV - Karmsidostyckesmyg, _constants.Karmoverstycke), new _paper.Size(Karmsidostyckesmyg, Sidokarmlangd)));
    colors.push(color); //underkarm

    rects.push(new _paper.Rectangle(new _paper.Point(0, karmH - _constants.Karmbottenstycke), new _paper.Size(karmV, _constants.Karmbottenstycke)));
    colors.push(color);
  } //pos : 3 är 1/3 av fönsterhöjden, 2 är 1/2 av fönsterhöjden, osv.


  function AddRombSprojs(p_fonsterstart, p_fonstersize, pos) {
    //p_fonsterstart = p_fonsterstart.add(new Point(Bagamnen, Bagamnen));
    //storfönster
    rects.push(new _paper.Rectangle(p_fonsterstart, p_fonstersize));
    colors.push(Fonsterfarg); //mittrekt

    var myrect = new _paper.Rectangle(new _paper.Point(p_fonsterstart.x + p_fonstersize.width / 2 - input.sprojs.size.x / 2, p_fonsterstart.y), new _paper.Size(_constants.Romb + _constants.Rombtra, _constants.Romb + _constants.Rombtra));
    myrect.center = new _paper.Point(p_fonsterstart.x + p_fonstersize.width / 2, p_fonsterstart.y + p_fonstersize.height / pos);
    rects.push(myrect);
    colors.push(color);
    rots.push(rects.length); //sprojsh

    rects.push(new _paper.Rectangle(new _paper.Point(p_fonsterstart.x, p_fonsterstart.y + p_fonstersize.height / pos - input.sprojs.size.x / 2), new _paper.Size(p_fonstersize.width, input.sprojs.size.x)));
    sprojsromb.push(rects.length - 1);
    colors.push(color); //sprojsv

    rects.push(new _paper.Rectangle(new _paper.Point(p_fonsterstart.x + p_fonstersize.width / 2 - input.sprojs.size.x / 2, p_fonsterstart.y), new _paper.Size(input.sprojs.size.x, p_fonstersize.height)));
    sprojsromb.push(rects.length - 1);
    colors.push(color); //mittrekt

    var myrect3 = new _paper.Rectangle(new _paper.Point(p_fonsterstart.x + p_fonstersize.width / 2 - input.sprojs.size.x / 2, p_fonsterstart.y), new _paper.Size(_constants.Romb + _constants.Rombtra - Linjebredd * 2, _constants.Romb + _constants.Rombtra - Linjebredd * 2));
    myrect3.center = new _paper.Point(p_fonsterstart.x + p_fonstersize.width / 2, p_fonsterstart.y + p_fonstersize.height / pos);
    rects.push(myrect3);
    sprojsromb.push(rects.length - 1);
    colors.push(color);
    rots.push(rects.length);
    lines.push(rects.length); //mittrekt

    var myrect2 = new _paper.Rectangle(new _paper.Point(p_fonsterstart.x + p_fonstersize.width / 2 - input.sprojs.size.x / 2, p_fonsterstart.y), new _paper.Size(_constants.Romb, _constants.Romb));
    myrect2.center = new _paper.Point(p_fonsterstart.x + p_fonstersize.width / 2, p_fonsterstart.y + p_fonstersize.height / pos);
    rects.push(myrect2);
    colors.push(Fonsterfarg);
    sprojsromb.push(rects.length - 1);
    rots.push(rects.length);
  }

  function AddFlagg(p_bagtopleft) {
    var flaggpostpos = flagg.y + _constants.Bagamnen * 2; //Sprojsfarg

    rects.push(new _paper.Rectangle(p_bagtopleft, new _paper.Size(Bagvidd, flaggpostpos)));
    colors.push(color); // FLAGGFÖNSTER
    //topbågeflaggF

    rects.push(new _paper.Rectangle(new _paper.Point(_constants.Bagamnen + p_bagtopleft.x, p_bagtopleft.y), new _paper.Size(Bagvidd - 2 * _constants.Bagamnen, _constants.Bagamnen)));
    colors.push(color);

    if (flagg.y != 0) {
      //vänsterbågeflaggF
      rects.push(new _paper.Rectangle(p_bagtopleft, new _paper.Size(_constants.Bagamnen, flaggpostpos)));
      colors.push(color); //högerbågeflaggF

      rects.push(new _paper.Rectangle(new _paper.Point(p_bagtopleft.x + Bagvidd - _constants.Bagamnen, p_bagtopleft.y), new _paper.Size(_constants.Bagamnen, flaggpostpos)));
      colors.push(color); //botbågeflaggF

      rects.push(new _paper.Rectangle(new _paper.Point(_constants.Bagamnen + p_bagtopleft.x, p_bagtopleft.y + flaggpostpos - _constants.Bagamnen), new _paper.Size(Bagvidd - 2 * _constants.Bagamnen, _constants.Bagamnen)));
      colors.push(color);
    } else {
      //vänsterbågeflaggF
      rects.push(new _paper.Rectangle(p_bagtopleft, new _paper.Size(_constants.Bagamnen, Bagvidd)));
      colors.push(color); //högerbågeflaggF

      rects.push(new _paper.Rectangle(new _paper.Point(p_bagtopleft.x + Bagvidd - _constants.Bagamnen, p_bagtopleft.y), new _paper.Size(_constants.Bagamnen, Bagvidd)));
      colors.push(color); //botbågeflaggF

      rects.push(new _paper.Rectangle(new _paper.Point(_constants.Bagamnen + p_bagtopleft.x, p_bagtopleft.y + Bagvidd - _constants.Bagamnen), new _paper.Size(Bagvidd - 2 * _constants.Bagamnen, _constants.Bagamnen)));
      colors.push(color);
    } //GLASFÖNSTER


    if (flagg.rombSprojs) {
      if (flagg.y != 0) {
        AddRombSprojs(p_bagtopleft.add(new _paper.Point(_constants.Bagamnen, _constants.Bagamnen)), new _paper.Size(Bagvidd - 2 * _constants.Bagamnen, flaggpostpos - 2 * _constants.Bagamnen), 2);
      } else {
        AddRombSprojs(p_bagtopleft.add(new _paper.Point(_constants.Bagamnen, _constants.Bagamnen)), new _paper.Size(Bagvidd - 2 * _constants.Bagamnen, Bagvidd - 2 * _constants.Bagamnen), 2);
      }
    } else {
      var FlaggFonsterstart = p_bagtopleft.add(new _paper.Point(_constants.Bagamnen, _constants.Bagamnen));
      var Fonsterh = (0, _measurements.getFlaggfonsterh)(karmV, luft, flagg.sprojsY, flagg.y, karmH, input.sprojs.size.y);
      /*(Bagvidd - Bagamnen * 2 - flagg.sprojsY * Sprojs) /
      (flagg.sprojsY + 1);*/

      var Fonsterv = (0, _measurements.getFlaggfonsterv)(karmV, luft, flagg.sprojsX, input.sprojs.size.x);
      /*(Bagvidd - Bagamnen * 2 - flagg.sprojsX * Sprojs) /
      (flagg.sprojsX + 1);*/

      for (var k = 0; k < 1 + flagg.sprojsX; k++) {
        for (var j = 0; j < 1 + flagg.sprojsY; j++) {
          rects.push(new _paper.Rectangle(new _paper.Point(FlaggFonsterstart.x + k * (Fonsterv + input.sprojs.size.x), FlaggFonsterstart.y + j * (Fonsterh + input.sprojs.size.y)), new _paper.Size(Fonsterv, Fonsterh)));
          colors.push(Fonsterfarg);
        }
      }
    } // FLAGGFÖNSTER SLUT


    var Fonstertoplefty;

    if (flagg.y != 0) {
      Fonstertoplefty = p_bagtopleft.y + flaggpostpos + _constants.Flaggavskiljare + _constants.Baghojdglapp;
    } else {
      Fonstertoplefty = p_bagtopleft.y + Bagvidd + _constants.Flaggavskiljare + _constants.Baghojdglapp;
    } //Sprojsfarg


    rects.push(new _paper.Rectangle(new _paper.Point(p_bagtopleft.x, Fonstertoplefty), new _paper.Size(Bagvidd, Baghojd //- (Fonstertoplefty - p_bagtopleft.y)
    )));
    colors.push(color); //topbåge

    rects.push(new _paper.Rectangle(new _paper.Point(_constants.Bagamnen + p_bagtopleft.x, Fonstertoplefty), new _paper.Size(Bagvidd - 2 * _constants.Bagamnen, _constants.Bagamnen)));
    colors.push(color); //botbåge

    rects.push(new _paper.Rectangle(new _paper.Point(_constants.Bagamnen + p_bagtopleft.x, Fonstertoplefty + Baghojd - _constants.Bagamnen //-(Fonstertoplefty - p_bagtopleft.y)
    ), new _paper.Size(Bagvidd - 2 * _constants.Bagamnen, _constants.Bagamnen)));
    colors.push(color); //vänsterbåge

    rects.push(new _paper.Rectangle(new _paper.Point(p_bagtopleft.x, Fonstertoplefty), new _paper.Size(_constants.Bagamnen, Baghojd //- (Fonstertoplefty - p_bagtopleft.y)
    )));
    colors.push(color); //högerbåge

    rects.push(new _paper.Rectangle(new _paper.Point(p_bagtopleft.x + Bagvidd - _constants.Bagamnen, Fonstertoplefty), new _paper.Size(_constants.Bagamnen, Baghojd //- (Fonstertoplefty - p_bagtopleft.y)
    )));
    colors.push(color);

    if (rombSprojs) {
      AddRombSprojs(new _paper.Point(p_bagtopleft.x + _constants.Bagamnen, Fonstertoplefty + _constants.Bagamnen), new _paper.Size(Bagvidd - _constants.Bagamnen * 2, Baghojd - //- (Fonstertoplefty - p_bagtopleft.y)
      _constants.Bagamnen * 2), 3);
    } else {
      //GLASFÖNSTER
      //const Fonsterstart = Bagtopleft.add(new Point(Bagamnen, Bagamnen));
      var fonsterstartx = p_bagtopleft.x + _constants.Bagamnen;
      var fonstersize2 = new _paper.Size(Fonstersize.width, (Baghojd - 2 * _constants.Bagamnen - sprojsY * input.sprojs.size.y) / (sprojsY + 1));

      for (var k = 0; k < 1 + sprojsX; k++) {
        for (var j = 0; j < 1 + sprojsY; j++) {
          rects.push(new _paper.Rectangle(new _paper.Point(fonsterstartx + k * (fonstersize2.width + input.sprojs.size.x), Fonstertoplefty + _constants.Bagamnen + j * (fonstersize2.height + input.sprojs.size.y)), fonstersize2));
          colors.push(Fonsterfarg);
        }
      }
    }
  }

  function AddFonster() {
    for (var i = 0; i < luft; i++) {
      var Bagtopleft = new _paper.Point(_constants.Karmsidostycke + Bagbreddglapp / 2 + (_constants.Mittpost + Bagvidd + Bagbreddglapp) * i, _constants.Karmoverstycke + 2 //2 mm av båghöjdsglappet
      );

      if (flagg) {
        AddFlagg(Bagtopleft);
      } else {
        rects.push(new _paper.Rectangle(Bagtopleft, new _paper.Size(Bagvidd, Baghojd)));
        colors.push(color); //topbåge

        rects.push(new _paper.Rectangle(new _paper.Point(_constants.Bagamnen + Bagtopleft.x, Bagtopleft.y), new _paper.Size(Bagvidd - 2 * _constants.Bagamnen, _constants.Bagamnen)));
        colors.push(color); //botbåge

        rects.push(new _paper.Rectangle(new _paper.Point(_constants.Bagamnen + Bagtopleft.x, Bagtopleft.y + Baghojd - _constants.Bagamnen), new _paper.Size(Bagvidd - 2 * _constants.Bagamnen, _constants.Bagamnen)));
        colors.push(color); //vänsterbåge

        rects.push(new _paper.Rectangle(Bagtopleft, new _paper.Size(_constants.Bagamnen, Baghojd)));
        colors.push(color); //högerbåge

        rects.push(new _paper.Rectangle(new _paper.Point(Bagtopleft.x + Bagvidd - _constants.Bagamnen, Bagtopleft.y), new _paper.Size(_constants.Bagamnen, Baghojd)));
        colors.push(color);

        if (rombSprojs) {
          AddRombSprojs(Bagtopleft.add(_constants.Bagamnen, _constants.Bagamnen), new _paper.Size(Bagvidd - _constants.Bagamnen * 2, Baghojd - _constants.Bagamnen * 2), 3);
        } else {
          //GLASFÖNSTER
          if (!input.sprojs.enkel) {
            var Fonsterstart = Bagtopleft.add(new _paper.Point(_constants.Bagamnen, _constants.Bagamnen));

            for (var k = 0; k < 1 + sprojsX; k++) {
              for (var j = 0; j < 1 + sprojsY; j++) {
                rects.push(new _paper.Rectangle(new _paper.Point(Fonsterstart.x + k * (Fonstersize.width + input.sprojs.size.x), Fonsterstart.y + j * (Fonstersize.height + input.sprojs.size.y)), Fonstersize));
                colors.push(Fonsterfarg);
              }
            }
          } else {
            var _Fonsterstart = Bagtopleft.add(new _paper.Point(_constants.Bagamnen, _constants.Bagamnen));

            for (var k = 0; k < 1 + sprojsX; k++) {
              rects.push(new _paper.Rectangle(new _paper.Point(_Fonsterstart.x + k * (Fonstersize.width + input.sprojs.size.x), _Fonsterstart.y), new _paper.Size(Fonstersize.width, input.sprojs.enkel.y)));
              colors.push(Fonsterfarg);
            }

            rects.push(new _paper.Rectangle(new _paper.Point(_Fonsterstart.x, _Fonsterstart.y + input.sprojs.size.y + input.sprojs.enkel.y), new _paper.Size(Bagvidd - 2 * _constants.Bagamnen, Baghojd - 2 * _constants.Bagamnen - (input.sprojs.size.y + input.sprojs.enkel.y))));
            colors.push(Fonsterfarg);
          }
        }
      } //Mittpost


      if (i > 0) {
        rects.push(new _paper.Rectangle(new _paper.Point(_constants.Karmsidostycke + (Bagvidd + Bagbreddglapp) * i + _constants.Mittpost * (i - 1), _constants.Karmoverstycke), new _paper.Size(_constants.Mittpost, Sidokarmlangd)));
        colors.push(color);
      }
    } //flaggFpost


    if (flagg) {
      if (flagg.y != 0) {
        rects.push(new _paper.Rectangle(new _paper.Point(0, _constants.Karmoverstycke + flagg.y + _constants.Bagamnen * 2 + _constants.Baghojdglapp), new _paper.Size(karmV, _constants.Flaggavskiljare)));
      } else {
        rects.push(new _paper.Rectangle(new _paper.Point(0, Bagvidd + _constants.Karmoverstycke + _constants.Baghojdglapp), new _paper.Size(karmV, _constants.Flaggavskiljare)));
      }

      colors.push(color);
    }
  }

  function DrawWindow() {
    var group1 = new _paper.Group();
    var rombgroup = new _paper.Group();
    var rombfonstergroup = new _paper.Group();

    for (var i = 0; i < rects.length; i++) {
      var myPath;
      var t_point = Karmstart.add(rects[i].topLeft.multiply(scale));
      var t_size = rects[i].size.multiply(scale); //var t_rect = new Rectangle(t_point, t_size);

      var t_rect = new _paper.Rectangle(t_point, t_size);
      myPath = new _paper.Path.Rectangle(t_rect);

      for (var j = 0; j < rects.length; j++) {
        if (i + 1 == rots[j]) {
          myPath.rotate(45);

          if (colors[i] == Fonsterfarg) {
            var t_path = new _paper.Path.Rectangle(t_rect);
            t_path.rotate(45); //t_path.clipMask = true;

            rombfonstergroup.addChild(t_path);
          }
        }
      }

      var strokecol = Linjebredd;

      for (var j = 0; j < rects.length; j++) {
        if (i + 1 == lines[j]) {
          strokecol = 0;
        }
      }

      for (var j = 0; j < rects.length; j++) {
        if (i == sprojsromb[j]) {
          rombgroup.addChild(myPath);
        }
      }

      myPath.style = {
        fillColor: colors[i],
        strokeColor: "black",
        strokeWidth: strokecol
      };

      if (colors[i] == Fonsterfarg) {
        var mapath = new _paper.Path.Rectangle(t_point, t_size); //mapath.clipMask = true;

        mapath.style = {
          fillColor: "black",
          strokeColor: "black",
          strokeWidth: 1
        };
        group1.addChild(mapath);
      }
    }

    var group = new _paper.Group();
    group1.clipMask = true;
    group.addChild(group1);
    var raster = new _paper.Raster({
      source: watermarkUrl,
      position: _paper.view.center
    });
    group.addChild(raster);
    rombgroup.bringToFront();

    if (rombSprojs || flagg !== null && flagg !== void 0 && flagg.rombSprojs) {
      rombfonstergroup.clipMask = true;
      var raster2group = new _paper.Group();
      raster2group.addChild(rombfonstergroup);
      var raster2 = new _paper.Raster({
        source: watermarkUrl,
        position: _paper.view.center
      });
      raster2group.addChild(raster2); //rombfonstergroup.addChild(raster2);

      raster2group.bringToFront();
    } //group.bringToFront();

  }

  function DrawText() {
    var textis = new _paper.PointText(Karmstart.x + karmV * scale / 2, Karmstart.y + karmH * scale + _constants.Karmoverstycke * scale + 12); //textis.scale(scale * 5);

    textis.justification = "center";
    textis.fillColor = "black";
    textis.content = Math.round(karmV * 100) / 100 + " mm"; //Bottentext

    var textis2 = new _paper.PointText(Karmstart.x - _constants.Karmoverstycke * scale - 6, Karmstart.y + karmH * scale / 2); //textis2.scale(scale * 5);

    textis2.justification = "center";
    textis2.fillColor = "black";
    textis2.content = Math.round(karmH * 100) / 100 + " mm";
    textis2.rotate(-90);
  }

  function getScale(karmV, karmH, width, height) {
    var ratio = width / height;
    return karmV >= karmH * ratio ? width / karmV : height / karmH;
  }
}