"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBagbreddglapp = getBagbreddglapp;
exports.getBaghojd = getBaghojd;
exports.getBagvidd = getBagvidd;
exports.getFlaggFonsterPostH = getFlaggFonsterPostH;
exports.getFlaggfonsterh = getFlaggfonsterh;
exports.getFlaggfonsterv = getFlaggfonsterv;
exports.getFonsterhojd = getFonsterhojd;
exports.getFonstervidd = getFonstervidd;
exports.getInreBagvidd = getInreBagvidd;
exports.getSprojshojd = getSprojshojd;
exports.getUndreBagoverkant = getUndreBagoverkant;

var _constants = require("./constants");

// alla beräkningar bör kunna genomföras med enbart de input-parametrar som användaren tillför, dvs "sprojsX", "sprojsY", "karmV", "karmH", "luft" och "farg"
// om varje beräkning kan isoleras i varsin funktion kan vi testa olika värden för att se att de stämmer, i measurements.test.js
// det gör det även enkelt för klienten att återanvända samma logik genom att importera funktionen

/*var sprojsL = 0;
export function setSprojs(intSprojs) {
  sprojsL = intSprojs;
}*/
function getFonstervidd(sprojsX, karmV, luft, sprojsSizeX) {
  // var Karmsidostyckesmyg = Smyg;
  // if (luft > 1) {
  //   Karmsidostyckesmyg = Karmsidostycke;
  // }
  var Fonstervidd = (getBagvidd(karmV, luft) - _constants.Bagamnen * 2 - sprojsX * sprojsSizeX
  /*[sprojsL]*/
  ) / (1 + sprojsX);
  return Fonstervidd;
}

function getBagvidd(karmV, luft) {
  var Karmsidostyckesmyg = _constants.Smyg;

  if (luft > 1) {
    Karmsidostyckesmyg = _constants.Karmsidostycke;
  }

  return (karmV - _constants.Karmsidostycke - Karmsidostyckesmyg - _constants.Mittpost * (luft - 1)) / luft - getBagbreddglapp(luft);
}

function getInreBagvidd(karmV, luft) {
  return getBagvidd(karmV, luft) - _constants.Bagamnen * 2;
}

function getFonsterhojd(sprojsY, karmH, karmV, flagg, luft, sprojsSizeY, enkel) {
  if (enkel) {
    return getBaghojd(karmH, karmV, flagg, luft) - 2 * _constants.Bagamnen - enkel.y - sprojsSizeY;
  } else {
    return (getBaghojd(karmH, karmV, flagg, luft) - 2 * _constants.Bagamnen - sprojsY * sprojsSizeY
    /*[sprojsL]*/
    ) / (1 + sprojsY);
  }
}

function getBaghojd(karmH, karmV, flagg, luft) {
  if (flagg) {
    if (flagg.y != 0) {
      return karmH - _constants.Karmbottenstycke - _constants.Karmoverstycke - flagg.y - _constants.Bagamnen * 2 - _constants.Baghojdglapp * 2 - _constants.Flaggavskiljare;
    } else {
      return karmH - _constants.Karmbottenstycke - _constants.Karmoverstycke - _constants.Baghojdglapp - (getBagvidd(karmV, luft) + _constants.Flaggavskiljare + _constants.Baghojdglapp);
    }
  } else {
    return karmH - _constants.Karmbottenstycke - _constants.Karmoverstycke - _constants.Baghojdglapp;
  }
}

function getSprojshojd(romb, sprojsY, karmH, karmV, luft, flagg, sprojsindex, sprojsSizeY, enkel) {
  //sprojsindex += 1;
  var sprojshojd;

  if (flagg) {
    var flaggSprojsY = flagg.sprojs.romb ? 1 : flagg.sprojs.y;

    if (sprojsindex <= flaggSprojsY) {
      if (flagg.sprojs.romb) {
        sprojshojd = _constants.Karmoverstycke + 2 + getBagvidd(karmV, luft) / 2;
      } else {
        sprojshojd = _constants.Karmoverstycke + 2 + _constants.Bagamnen + getFlaggfonsterh(karmV, luft, flaggSprojsY, flagg.y, karmH, sprojsSizeY) * sprojsindex + sprojsSizeY
        /*[sprojsL]*/
        * (sprojsindex - 1) + sprojsSizeY
        /*[sprojsL]*/
        / 2;
      }
    } else {
      if (romb) {
        sprojshojd = _constants.Karmoverstycke + getBagvidd(karmV, luft) + _constants.Baghojdglapp + _constants.Flaggavskiljare + 2
        /*2mm av båghöjdsglappet*/
        + _constants.Bagamnen + (getBaghojd(karmH, karmV, flagg, luft) - 2 * _constants.Bagamnen) / 3;
      } else {
        sprojshojd = _constants.Karmoverstycke + getBagvidd(karmV, luft) + _constants.Baghojdglapp + _constants.Flaggavskiljare + 2
        /*2mm av båghöjdsglappet*/
        + _constants.Bagamnen + getFonsterhojd(sprojsY, karmH, karmV, flagg, luft, sprojsSizeY, enkel) * (sprojsindex - flaggSprojsY) + sprojsSizeY
        /*[sprojsL]*/
        * (sprojsindex - 1) + sprojsSizeY
        /*[sprojsL]*/
        / 2;
      }
    }
  } else {
    if (romb) {
      sprojshojd = _constants.Karmoverstycke + 2
      /*2mm av båghöjdsglappet*/
      + _constants.Bagamnen + getFonsterhojd(0, karmH, karmV, flagg, luft, sprojsSizeY) / 3;
    } else if (enkel) {
      sprojshojd = _constants.Karmoverstycke + 2
      /*2mm av båghöjdsglappet*/
      + _constants.Bagamnen + enkel.y + sprojsSizeY / 2;
    } else {
      sprojshojd = _constants.Karmoverstycke + 2
      /*2mm av båghöjdsglappet*/
      + _constants.Bagamnen + getFonsterhojd(sprojsY, karmH, karmV, flagg, luft, sprojsSizeY) * sprojsindex + sprojsSizeY
      /*[sprojsL]*/
      * (sprojsindex - 1) + sprojsSizeY
      /*[sprojsL]*/
      / 2;
    }
  }

  return sprojshojd;
}

function getUndreBagoverkant(karmH) {
  var undreBagoverkant = karmH - _constants.Karmbottenstycke - (_constants.Baghojdglapp - 1) - _constants.Bagamnen;
  return undreBagoverkant;
}

function getBagbreddglapp(luft) {
  var t_Bagbreddglapp = 0;

  if (luft == 1) {
    t_Bagbreddglapp = 5;
  } else if (luft == 2) {
    t_Bagbreddglapp = 4;
  } else if (luft == 3) {
    t_Bagbreddglapp = 4 + 2 / 3;
  } else if (luft == 4) {
    t_Bagbreddglapp = 6;
  } else if (luft == 5) {
    t_Bagbreddglapp = 5.8;
  } else if (luft == 6) {
    t_Bagbreddglapp = 5 + 2 / 3;
  }

  return t_Bagbreddglapp;
}

function getFlaggfonsterh(karmV, luft) {
  var flaggsprojsY = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var flaggY = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  var karmH = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
  var sprojsSizeY = arguments.length > 5 ? arguments[5] : undefined;

  if (flaggY != 0) {
    var Fonsterh = (flaggY - flaggsprojsY * sprojsSizeY) / (flaggsprojsY + 1);
    return Fonsterh;
  } else {
    var _Fonsterh = (getBagvidd(karmV, luft) - _constants.Bagamnen * 2 - flaggsprojsY * sprojsSizeY
    /*[sprojsL]*/
    ) / (flaggsprojsY + 1);

    return _Fonsterh;
  }
}

function getFlaggFonsterPostH(karmH, flaggY) {
  return (karmH + _constants.Bagamnen * 2 + flaggY + _constants.Baghojdglapp + _constants.Flaggavskiljare) / 2;
}

function getFlaggfonsterv(karmV, luft) {
  var flaggsprojsX = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var sprojsSizeX = arguments.length > 3 ? arguments[3] : undefined;
  var Fonsterv = (getBagvidd(karmV, luft) - _constants.Bagamnen * 2 - flaggsprojsX * sprojsSizeX
  /*[sprojsL]*/
  ) / (flaggsprojsX + 1);
  return Fonsterv;
}