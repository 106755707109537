import { Configurator } from "@akvalite/configurator";
import "./app.css";

export default function App() {
  return (
    <div className="app">
      <h1>Fönsterritare</h1>
      <Configurator admin watermarkUrl="/logo.png"></Configurator>
    </div>
  );
}
