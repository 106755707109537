"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseIfInt = void 0;

var parseIfInt = function parseIfInt(value) {
  var int = parseInt(value);
  return isNaN(int) ? value : int;
};

exports.parseIfInt = parseIfInt;