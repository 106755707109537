"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.inputReducer = exports.initialState = exports.ACTION = void 0;

var _constants = require("../utils/constants");

var _shortcode = require("../utils/shortcode");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/** Using my own set of constants here, have some additional margin and are not really exact */
var KARM_MARGIN = _constants.Karmsidostycke * 2 + _constants.Bagamnen * 2;
var BAG_MARGIN = 126;
var ROMB_SIZE = 200;
var ACTION = {
  SUBMIT_CODE: "SUBMIT_CODE",
  SET_MEASUREMENTS: "SET_MEASUREMENTS",
  SET_COLOR: "SET_COLOR",
  SET_FLAGG: "SET_FLAGG",
  SET_SPROJS: "SET_SPROJS"
};
exports.ACTION = ACTION;

var getMinBagBredd = function getMinBagBredd(_ref) {
  var flagg = _ref.flagg,
      sprojs = _ref.sprojs;
  var minFlaggFonster = flagg ? flagg.sprojs.romb ? ROMB_SIZE : flagg.sprojs.x * sprojs.size.x : 0;
  return Math.max(minFlaggFonster, sprojs.romb ? ROMB_SIZE : sprojs.x * sprojs.size.x);
};

var getMinKarmV = function getMinKarmV(_ref2) {
  var flagg = _ref2.flagg,
      sprojs = _ref2.sprojs,
      measurements = _ref2.measurements;
  var minBagBredd = getMinBagBredd({
    flagg: flagg,
    sprojs: sprojs
  });
  return KARM_MARGIN + minBagBredd * measurements.luft + BAG_MARGIN * (measurements.luft - 1);
};

var getMaxKarmV = function getMaxKarmV(_ref3) {
  var flagg = _ref3.flagg,
      measurements = _ref3.measurements,
      sprojs = _ref3.sprojs;
  return flagg ? Math.max(measurements.h * measurements.luft - 400 - 40 * sprojs.y, 0) : 10000;
};

var getMinKarmH = function getMinKarmH(_ref4) {
  var flagg = _ref4.flagg,
      sprojs = _ref4.sprojs,
      measurements = _ref4.measurements;
  var minFlaggH = flagg ? measurements.v / measurements.luft + 20 : 0;
  return 200 + minFlaggH + (sprojs.romb ? ROMB_SIZE : sprojs.y * sprojs.size.y);
};

var getMaxKarmH = function getMaxKarmH() {
  return 10000;
};

var getMaxSprojsX = function getMaxSprojsX(_ref5) {
  var measurements = _ref5.measurements,
      sprojs = _ref5.sprojs;
  return sprojs.romb ? Infinity : Math.floor((measurements.v - KARM_MARGIN) / measurements.luft / sprojs.size.x);
};

var getMinLuft = function getMinLuft(_ref6) {
  var measurements = _ref6.measurements,
      flagg = _ref6.flagg;
  return flagg ? Math.ceil(measurements.v / measurements.h) : 0;
};

var getMaxLuft = function getMaxLuft(_ref7) {
  var flagg = _ref7.flagg,
      sprojs = _ref7.sprojs,
      measurements = _ref7.measurements;
  var minBagBredd = getMinBagBredd({
    flagg: flagg,
    sprojs: sprojs
  });
  return Math.floor((measurements.v - KARM_MARGIN + BAG_MARGIN) / (minBagBredd + BAG_MARGIN));
};

var getValidations = function getValidations(state) {
  return {
    measurements: {
      v: {
        min: getMinKarmV(state),
        max: getMaxKarmV(state)
      },
      h: {
        min: getMinKarmH(state),
        max: getMaxKarmH(state)
      },
      luft: {
        min: getMinLuft(state),
        max: getMaxLuft(state)
      }
    },
    sprojs: {
      x: {
        min: 0,
        max: getMaxSprojsX(state)
      },
      y: {
        min: 0,
        max: getMaxSprojsX(state)
      }
    },
    flagg: {
      sprojs: {
        x: {
          min: 0,
          max: getMaxSprojsX(state)
        },
        y: {
          min: 0,
          max: 8
        }
      }
    }
  };
};

var validateMeasurements = function validateMeasurements(measurements, validation) {
  var v = validation.v,
      h = validation.h,
      luft = validation.luft;
  return v.min <= measurements.v && v.max >= measurements.v && h.min <= measurements.h && h.max >= measurements.h && luft.min <= measurements.luft && luft.max >= measurements.luft;
};

var validateSprojs = function validateSprojs(sprojs, validation) {
  var x = validation.x,
      y = validation.y;
  return x.min <= sprojs.x && x.max >= sprojs.x && y.min <= sprojs.y && y.max >= sprojs.y;
};

var validateFlagg = function validateFlagg(flagg, validation) {
  var _flagg$sprojs, _flagg$sprojs2, _flagg$sprojs3, _flagg$sprojs4;

  var sprojs = validation.sprojs;
  return flagg ? sprojs.x.min <= (flagg === null || flagg === void 0 ? void 0 : (_flagg$sprojs = flagg.sprojs) === null || _flagg$sprojs === void 0 ? void 0 : _flagg$sprojs.x) && sprojs.x.max >= (flagg === null || flagg === void 0 ? void 0 : (_flagg$sprojs2 = flagg.sprojs) === null || _flagg$sprojs2 === void 0 ? void 0 : _flagg$sprojs2.x) && sprojs.y.min <= (flagg === null || flagg === void 0 ? void 0 : (_flagg$sprojs3 = flagg.sprojs) === null || _flagg$sprojs3 === void 0 ? void 0 : _flagg$sprojs3.y) && sprojs.y.max >= (flagg === null || flagg === void 0 ? void 0 : (_flagg$sprojs4 = flagg.sprojs) === null || _flagg$sprojs4 === void 0 ? void 0 : _flagg$sprojs4.y) : true;
};

var getStateErrors = function getStateErrors(state, validations) {
  return {
    measurements: !validateMeasurements(state.measurements, validations.measurements),
    sprojs: !validateSprojs(state.sprojs, validations.sprojs),
    flagg: !validateFlagg(state.flagg, validations.flagg)
  };
};

var setState = function setState(oldState, newState) {
  var state = _objectSpread(_objectSpread({}, oldState), newState);

  var validations = getValidations(state);
  var errors = getStateErrors(state, validations);
  var isValid = Object.values(errors).every(function (error) {
    return !error;
  });
  return _objectSpread(_objectSpread({}, state), {}, {
    valid: isValid ? state : oldState.valid,
    validations: validations,
    errors: errors
  });
};

var inputReducer = function inputReducer(state, action) {
  switch (action.type) {
    case ACTION.SUBMIT_CODE:
      {
        var input = (0, _shortcode.parseInputFromCode)(action.payload);
        return setState(state, input);
      }

    case ACTION.SET_MEASUREMENTS:
      {
        return setState(state, {
          measurements: action.payload
        });
      }

    case ACTION.SET_COLOR:
      {
        return setState(state, {
          farg: action.payload
        });
      }

    case ACTION.SET_FLAGG:
      {
        return setState(state, {
          flagg: action.payload,
          sprojs: _objectSpread(_objectSpread({}, state.sprojs), {}, {
            enkel: false
          })
        });
      }

    case ACTION.SET_SPROJS:
      {
        if (action.payload.enkel) {
          return setState(state, {
            sprojs: action.payload,
            flagg: false
          });
        }

        return setState(state, {
          sprojs: action.payload
        });
      }

    default:
      {
        return state;
      }
  }
};

exports.inputReducer = inputReducer;

var initialState = _objectSpread(_objectSpread({}, _constants.DEFAULT_INPUT), {}, {
  valid: _constants.DEFAULT_INPUT,
  validations: getValidations(_constants.DEFAULT_INPUT),
  errors: {
    measurements: false,
    sprojs: false,
    flagg: false
  }
});

exports.initialState = initialState;