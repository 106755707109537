"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseInputFromCode = exports.generateCodeFromInput = void 0;

var _constants = require("./constants");

var _number = require("./number");

var _object = require("./object");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var PARAM_DICTIONARY = {
  "measurements.luft": "L",
  "measurements.v": "V",
  "measurements.h": "H",
  "flagg.sprojs.romb": "FR",
  "flagg.sprojs.x": "FX",
  "flagg.sprojs.y": "FY",
  "sprojs.romb": "R",
  "sprojs.x": "X",
  "sprojs.y": "Y",
  farg: "C"
};
var VALUE_MAP = {
  T: true
};

var generateCodeFromInput = function generateCodeFromInput(input, directory) {
  return Object.entries(input).reduce(function (parts, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    var paramKey = directory ? "".concat(directory, ".").concat(key) : key;

    if ((0, _object.isObjectLiteral)(value)) {
      var part = generateCodeFromInput(value, paramKey);
      if (part !== "") parts.push(part);
    } else {
      var _Object$entries$find$, _Object$entries$find;

      var param = PARAM_DICTIONARY[paramKey];
      var mappedValue = (_Object$entries$find$ = (_Object$entries$find = Object.entries(VALUE_MAP).find(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
            _k = _ref4[0],
            v = _ref4[1];

        return v === value;
      })) === null || _Object$entries$find === void 0 ? void 0 : _Object$entries$find[0]) !== null && _Object$entries$find$ !== void 0 ? _Object$entries$find$ : value;
      if (param && value) parts.push("".concat(param, "=").concat(mappedValue));
    }

    return parts;
  }, []).join(",");
};

exports.generateCodeFromInput = generateCodeFromInput;

var parseInputFromCode = function parseInputFromCode(code) {
  var params = code.split(",");
  return params.reduce(function (input, param) {
    var _Object$entries$find2, _VALUE_MAP$paramValue;

    var _param$split = param.split("="),
        _param$split2 = _slicedToArray(_param$split, 2),
        paramKey = _param$split2[0],
        paramValue = _param$split2[1];

    var key = (_Object$entries$find2 = Object.entries(PARAM_DICTIONARY).find(function (_ref5) {
      var _ref6 = _slicedToArray(_ref5, 2),
          _k = _ref6[0],
          v = _ref6[1];

      return v === paramKey;
    })) === null || _Object$entries$find2 === void 0 ? void 0 : _Object$entries$find2[0];
    if (!key) return input;
    var value = (_VALUE_MAP$paramValue = VALUE_MAP[paramValue]) !== null && _VALUE_MAP$paramValue !== void 0 ? _VALUE_MAP$paramValue : (0, _number.parseIfInt)(paramValue);

    var _key$split$reverse = key.split(".").reverse(),
        _key$split$reverse2 = _toArray(_key$split$reverse),
        last = _key$split$reverse2[0],
        keyParts = _key$split$reverse2.slice(1);

    var directory = keyParts.reduceRight(function (acc, keyPart) {
      return acc[keyPart] || (acc[keyPart] = {});
    }, input);
    directory[last] = value;
    return input;
  }, _objectSpread({}, _constants.DEFAULT_INPUT));
};

exports.parseInputFromCode = parseInputFromCode;