"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Smyg = exports.SPROJS_SIZE_THICK = exports.SPROJS_SIZE_REGULAR = exports.Rombtra = exports.Romb = exports.Mittpost = exports.Karmsidostycke = exports.Karmoverstycke = exports.Karmbottenstycke = exports.Flaggavskiljare = exports.DEFAULT_INPUT = exports.COLORS = exports.Baghojdglapp = exports.Bagamnen = void 0;
var Karmoverstycke = 33;
exports.Karmoverstycke = Karmoverstycke;
var Karmbottenstycke = 29;
exports.Karmbottenstycke = Karmbottenstycke;
var Karmsidostycke = 33;
exports.Karmsidostycke = Karmsidostycke;
var Mittpost = 20;
exports.Mittpost = Mittpost;
var Bagamnen = 47;
exports.Bagamnen = Bagamnen;
var SPROJS_SIZE_REGULAR = 23.5;
exports.SPROJS_SIZE_REGULAR = SPROJS_SIZE_REGULAR;
var SPROJS_SIZE_THICK = 46;
exports.SPROJS_SIZE_THICK = SPROJS_SIZE_THICK;
var Baghojdglapp = 14;
exports.Baghojdglapp = Baghojdglapp;
var Smyg = 27;
exports.Smyg = Smyg;
var Romb = 120;
exports.Romb = Romb;
var Rombtra = 48;
exports.Rombtra = Rombtra;
var Flaggavskiljare = 18;
exports.Flaggavskiljare = Flaggavskiljare;
var COLORS = [{
  name: "Vit",
  color: "#FEFFFE"
}, {
  name: "Järnoxidröd",
  color: "#711203"
}, {
  name: "Oxidblå",
  color: "#011263"
}, {
  name: "Grön umbra",
  color: "#362D1C"
}, {
  name: "Järnoxidsvart",
  color: "#1D1C1D"
}, {
  name: "Grå umbra",
  color: "#615F5E"
}, {
  name: "Bränd Terra",
  color: "#812D0B"
}, {
  name: "Obränd Terra",
  color: "#814D01"
}, {
  name: "Engelsk röd",
  color: "#791203"
}, {
  name: "Järnoxidbrun",
  color: "#37190D"
}, {
  name: "Kromoxidgrön",
  color: "#3E6A39"
}, {
  name: "Ljusockra",
  color: "#9A5D03"
}, {
  name: "Järnoxidgul",
  color: "#B77201"
}, {
  name: "Guldockra",
  color: "#AD6917"
}];
exports.COLORS = COLORS;
var DEFAULT_INPUT = {
  measurements: {
    luft: 2,
    v: 1000,
    h: 800
  },
  sprojs: {
    romb: false,
    x: 1,
    y: 1,
    size: {
      x: SPROJS_SIZE_REGULAR,
      y: SPROJS_SIZE_REGULAR
    },
    enkel: false
  },
  flagg: false,
  farg: COLORS[0].color
};
exports.DEFAULT_INPUT = DEFAULT_INPUT;